import {
  faDiscord,
  faGithub,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type SVGProps } from "react";

export const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 27 32"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M0 32V11.64L5.81 0h14.55l5.82 11.64V32h-5.82V11.64l-2.91-5.81H8.72l-2.9 5.81v2.9h5.82l2.9 2.93h2.9v2.9H5.82V32H0Z" />
  </svg>
);

export const Github = () => <FontAwesomeIcon icon={faGithub} />;
export const LinkedIn = () => <FontAwesomeIcon icon={faLinkedinIn} />;
export const Discord = () => <FontAwesomeIcon icon={faDiscord} />;
export const Twitter = () => <FontAwesomeIcon icon={faTwitter} />;
export const Hamburger = () => <FontAwesomeIcon icon={faBars} />;
