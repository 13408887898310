"use client";

import clsx from "clsx";
import { useEffect, useState } from "react";

function InitOverlay() {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    let timeoutId: number | undefined;
    const delayedUpdateLoaded = () => {
      timeoutId = window.setTimeout(() => {
        setHasLoaded(true);
      }, 300);
    };

    // Already fully loaded:
    if (document.readyState === "complete") {
      delayedUpdateLoaded();
      return () => window.clearTimeout(timeoutId);
    }

    // Otherwise, wait for the load event:
    const callback = () => {
      delayedUpdateLoaded();
    };

    window.addEventListener("load", callback);

    return () => {
      window.removeEventListener("load", callback);
      window.clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div
      className={clsx(
        "pointer-events-none fixed inset-0 bg-dark transition-opacity duration-700 ease-out z-50",
        {
          "opacity-0": hasLoaded,
        },
      )}
    >
      <noscript>
        <div className="pointer-events-auto flex h-full items-center justify-center">
          <p className="text-3xl text-white">
            Please enable JavaScript to continue.
          </p>
        </div>
      </noscript>
    </div>
  );
}

export default InitOverlay;
