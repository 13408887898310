import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@15.0.0-rc.0_react-dom@19.0.0-rc-01172397-20240716_react@19.0.0-r_yjp5mrbrwqvs4s7qc7xeyl7esi/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@15.0.0-rc.0_react-dom@19.0.0-rc-01172397-20240716_react@19_hpkxupyj76vkr7l25riv53r3py/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-rc.0_react-dom@19.0.0-rc-01172397-20240716_react@19.0.0-rc-01172397-20240716__rea_mbyvsc3bfdkjqldixat2ldmkf4/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Lexend\",\"arguments\":[{\"weight\":[\"400\",\"600\",\"700\"],\"subsets\":[\"latin\"]}],\"variableName\":\"lexend\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@fortawesome+fontawesome-svg-core@6.6.0/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/InitOverlay.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Navbar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
