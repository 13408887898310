"use client";

import * as Switch from "@radix-ui/react-switch";
import clsx from "clsx";
import Link from "next/link";
import { useState } from "react";
import { Github, LinkedIn, Logo } from "./Icons";

const itemStyles =
  "inline py-4 font-semibold transition-colors hover:text-primary";

const Item = (props: {
  href: string;
  children: React.ReactNode;
  external?: boolean;
  onClick: () => void;
}) => (
  <Link
    target={props.external ? "_blank" : undefined}
    className={itemStyles}
    href={props.href}
    onClick={props.onClick}
  >
    {props.children}
  </Link>
);

export const HamburgerButton = (props: Switch.SwitchProps) => {
  const names = "h-1 bg-current rounded-full w-full transition duration-300";
  return (
    <Switch.Root
      className={clsx(
        itemStyles,
        "group flex w-7 flex-col justify-evenly transition duration-300",
      )}
      {...props}
    >
      <span
        className={clsx(
          names,
          "group-data-[state=checked]:translate-y-3 group-data-[state=checked]:rotate-[45deg] group-data-[state=checked]:scale-x-125",
        )}
      />
      <span className={clsx(names, "group-data-[state=checked]:opacity-0")} />
      <span
        className={clsx(
          names,
          "group-data-[state=checked]:-translate-y-2 group-data-[state=checked]:-rotate-[45deg] group-data-[state=checked]:scale-x-125",
        )}
      />
    </Switch.Root>
  );
};

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  return (
    <>
      <nav className="relative z-20 mx-auto my-4 flex w-full max-w-page items-center justify-between px-8 text-3xl text-white">
        <Item onClick={close} href="/">
          <Logo />
        </Item>
        <div className="flex gap-6">
          <Item onClick={close} external href="https://github.com/alvesvaren">
            <Github />
          </Item>
          <Item
            onClick={close}
            external
            href="https://www.linkedin.com/in/alvesvaren"
          >
            <LinkedIn />
          </Item>
          <HamburgerButton checked={open} onCheckedChange={setOpen} />
        </div>
      </nav>
      <aside>
        <nav
          className={clsx(
            "xs:text-4xl fixed right-0 top-0 z-10 flex h-full w-full max-w-full flex-col justify-center border-white/10 bg-dark/95 px-4 text-center text-3xl text-white backdrop-blur-md transition duration-300 sm:w-max sm:border-l sm:px-10",
            open
              ? "transform-none opacity-100"
              : "translate-x-full transform opacity-0",
          )}
        >
          <Item onClick={close} href="/">
            Start page
          </Item>
          <Item onClick={close} href="/projects">
            Explore projects
          </Item>
          <Item onClick={close} href="/posts">
            View my posts
          </Item>
          <Item onClick={close} href="/alve-cv.pdf">
            Download CV
          </Item>
        </nav>
      </aside>
    </>
  );
}
